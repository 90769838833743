import React from "react"
import Reveal from "react-reveal/Reveal"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutComputerImage: file(relativePath: { eq: "about_computer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      cloud: file(relativePath: { eq: "seo/cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      site {
        siteMetadata {
          urls {
            register
          }
          social {
            twitter
            linkedIn
          }
        }
      }
    }
  `)

  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title="About" />
      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                About Us
              </h1>
            </Reveal>
          </div>
        </div>
      </section>
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb_90">
            Making webhooks easy to use
          </h2>
          <div className="row mb-5">
            <div className="col-lg-6 text-center">
              <img src={require("../images/seo/cloud.png")} alt="cloud" />
            </div>
            <div className="col-lg-6">
              <p>
                Integrations, and more specifically webhooks, are a universal
                pain for developers. They are always the last thing to be added
                as an afterthought. As good engineers, we know that proper
                testing is important, and yet webhooks are the hardest thing to
                test.
              </p>
              <p>
                Every third-party has a different API to create and manage them.
                The incoming data is always different, and if you’re lucky
                you’ll get some test data to send to your API. More often than
                not, webhook endpoints are pushed to production without proper
                testing.
              </p>
            </div>
          </div>
          <div className="row mb-5 flex-row-reverse">
            <div className="col-lg-6 text-center">
              <img
                src={require("../images/seo/image.png")}
                style={{ maxWidth: "75%" }}
                alt="people working together at whiteboard"
              />
            </div>
            <div className="col-lg-6">
              <p>
                HookActions was started to solve these problems. As developers
                we want to feel confident when pushing that deploy button with
                our code. For most third-party integrations, we can’t feel this
                way.
              </p>
              <p>
                Elijah worked at many tech companies in Silicon Valley and
                Europe, after having to integrate 100s of services he found that
                there was not a consistent way to manage them. Testing was even
                more difficult. You can send test data using other applications,
                but it’s just your best guess of what the data will look like.
                More often than not, he found the company pushing webhooks to
                production and debugging them live.
              </p>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6 text-center">
              <img
                src={require("../images/login_img.png")}
                style={{ maxWidth: "75%" }}
                alt="person with glasses sitting at computer with coffee"
              />
            </div>
            <div className="col-lg-6">
              <p>
                This is not how good software should be built, yet we’re all
                guilty of doing this with webhooks because they’re so
                unpredictable. Start using HookActions today as your single
                source of truth for all your third-party webhooks.
              </p>
              <p>
                Push to production faster and with more confidence using our
                command line interface to send real events to your local server.
                Spend time building the exciting features you wanted to build at
                your company and less time figuring out how the webhook data is
                structured and sent to your server.
              </p>
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <Img
                fluid={data.aboutComputerImage.childImageSharp.fluid}
                className="shadow-sm rounded"
                alt="computer desktop with keyboard and mouse"
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-around text-center">
              <p>
                HookActions is a completely bootstrapped company headquartered
                in the historic Ferris Wheel building in downtown Flint,
                Michigan.
              </p>
              <div>
                <p>
                  Say hi on social media
                  <span className="f_social_icon">
                    <a
                      href={data.site.siteMetadata.social.twitter}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      href={data.site.siteMetadata.social.linkedIn}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </span>
                </p>
              </div>
              <div>
                <a
                  href={data.site.siteMetadata.urls.register}
                  className="seo_btn seo_btn_one btn_hover"
                >
                  Create your account
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
